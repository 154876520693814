:root {
    --primary: #0A66C2;
    --hover-bg: #ffffff;
    --hover-color: #0072B1;
    --white: #fff;
}

.btn {
    text-decoration: none !important;
    color: var(--white) ;
    padding: 10px 20px !important;
    border-radius: 15px !important;
    cursor: pointer !important;
    display: inline-block !important;
    font-weight: bold ;
    text-align: center !important;
    transition: all 0.3s ease !important;
}

.btn--primary {
    color: white !important;
    background-color: var(--primary) !important;
    border: 1px solid var(--white)!important;
}

.btn--outline {
    font-size: 16px;
    background-color: transparent !important;
    color: var(--white) !important;
    border: 1px solid var(--white) !important;
}

.btn--outline:hover {
    background-color: var(--hover-bg) !important;
}

.btn--primary:hover {
    background-color: var(--hover-bg)!important;
}

.btn--medium {
    padding: 8px 20px!important; 
    font-size: 12px !important;
}

.btn--large {
    padding: 12px 26px !important;
    font-size: 17px !important;
}

.btn--medium:hover, .btn--large:hover {
    color: var(--hover-color) !important;
}
