body {
    font-size: 16px;
}

.project-details {
    padding: 20px;
    background-color: #e3f2ee;
    width: 70%;
    margin-left:auto;
    margin-right:auto;
    margin-top: 50px;
    margin-bottom: 50px;
    border-radius: 15px;
    text-align: left;
    padding: 50px 100px;
  }

  .project-details h1 {
    text-align: left;
    font-size: 1.75rem;
    font-weight: 700;
    margin-bottom: 15px;
    color: #0A66C2;
  }

  .project-details h2 {
    font-size: 1.25rem;
    font-weight: 500;
    margin-bottom: 20px; 
  }
  
  .button-section {
    margin-top: 20px;
  }
  
  .button-section .btn {
    font-size: 0.8rem;
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 10px 20px;
    color: #ffffff;
    text-decoration: none;
    background-color: #0A66C2;
    border-color: rgb(255, 255, 255);
    border-radius: 15px;
    transition: background-color 0.3s;
  }
  
  .button-section .btn:hover {
    background-color: #e3f2ee;
    color: #0072B1;
    border-color:#0072B1;
  }

  .screenshots {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    margin-top: 1rem;
  }
  
  .screenshot {
    max-width: 100%;
    height: auto;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  .screenshots.ribbit-images {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }
  
  .screenshots img {
    max-width: 100%;
    height: auto;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  

  @media screen and (max-width: 960px) {
    .project-details {
      padding: 20px;
      width: 90%;
      margin-left:auto;
      margin-right:auto;
      margin-top: 50px;
      margin-bottom: 50px;
      border-radius: 15px;
      text-align: left;
      padding: 20px 40px;
    }

    
    
  }
  