.navbar {
  background: linear-gradient(90deg, #16222A,#3A6073);

  height: 80px; /* Adjust height here if needed */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 999;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%; 
  width: 100%;
  padding: 0 50px;
}

.navbar-logo {
  color: #fff;
  margin-left: 0; 
  cursor: crosshair;
  text-decoration: none;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
}

.fa-sun {
  color: yellow;
  margin-left: 1rem;
  font-size: 1.6rem;
}

.navbar-logo i:hover {
  animation: spin 1s infinite linear;
}

@keyframes spin {
  100% {
      transform: rotate(360deg);
  }
}

.nav-menu {
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  margin-top: 80px;
  padding-left: 0;
  flex-grow: 1;
  justify-content: flex-end;
}

.nav-item {
  height: 80px;
}

.nav-links {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
}

.nav-links:hover {
  border-bottom: 4px solid #fff;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

.btn--contact {
  color: red !important;
  font-size: 25px !important;
}


@media screen and (max-width: 960px) {
  .NavbarItems {
      position: relative;
  }

  .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100vh;
      position: absolute;
      top: 80px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
      justify-content: center; /* Center vertically */
      align-items: center; /* Center horizontally */
  }

  .nav-menu.active {
      background: #242222;
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
  }

  .nav-links {
      text-align: center;
      padding: 1rem 0; /* Adjust padding */
      width: 100%;
      display: table;
  }

  .nav-links:hover {
      background-color: #fff;
      color: #242424;
      border-radius: 0;
  }

  .navbar-logo {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(25%, 50%);
    }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 24px; /* Adjust right spacing if needed */
    transform: translate(-100%, 0%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 1rem 0; /* Adjust top margin */
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #242424;
    transition: 250ms;
  }
}