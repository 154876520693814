.about-container {
    margin-top: 100px;
    margin-bottom: 100px;
    background-image: linear-gradient(135deg, #02aab0, #00cdac);
    clip-path: polygon(0 18%, 100% 0, 100% 82%, 0 100%);
    color: white;
    padding: 150px 0;
  }
  
  .about-content {
    position: relative;
    top: 15%;
    padding: 25px 15px;
  }
  
  .about-header {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 50px;
  }
  
  .about-text {
    text-align: center;
    line-height: 25px;
    font-size: 1.1rem;
    font-weight: 400;
  }
  
  .section-divider {
    display: flex;
    flex-wrap: wrap;
  }
  
  .about-image {
    width: 50%;
    height: auto;
    margin-left: 20%;
  }



  
  @media (max-width: 768px) {

    .about-container {
        margin-top: 100px;
        margin-bottom: 100px;
        background-image: linear-gradient(135deg, #02aab0, #00cdac);
        clip-path: polygon(0 10%, 100% 0, 100% 90%, 0 100%);
        color: white;
        padding: 100px 0;
      }

    .about-text {
      text-align: center;
      margin-top: 20px;
    }


  }
  