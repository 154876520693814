/* Header */
.large-header {
    position: relative;
    width: 100%;
    background: #333;
    overflow: hidden;
    background-size: cover;
    background-position: center center;
    z-index: 1;
  }
  
  #large-header {
    /* background-image: url('https://www.marcoguglie.it/Codepen/AnimatedHeaderBg/demo-1/img/demo-1-bg.jpg'); */
    background: linear-gradient(90deg, #16222A,#3A6073);

  }
  
  .demo-1 .main-title {
    text-transform: uppercase;
    font-size: 4.2em;
    letter-spacing: 0.1em;
  }
  
  .main-title .thin {
    font-weight: 200;
  }
  
  @media only screen and (max-width: 768px) {
    .demo-1 .main-title {
      font-size: 3em;
    }
  }
  