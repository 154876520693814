.footer-container {
    background-color: #242424;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .footer-subscription {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 24px;
    color: #fff;
  }
  
  
  .footer-subscription-heading {
    font-size: 16px;
  }

  
  /* src/components/Footer.css */
.footer-container {

  text-align: center;
}

.footer-subscription {
  max-width: 1000px;
  margin: 0 auto;
}

.footer-contact {
  font-size: 18px;
  margin-bottom: 20px;
}

.footer-contact a {
  color: #73e8ee;
  text-decoration: none;
}

.footer-contact a:hover {
  text-decoration: underline;
}

.footer-subscription-heading {
  font-size: 20px;
  margin-bottom: 10px;
}
